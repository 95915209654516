var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product lg:flex lg:flex-col lg:bg-white"},[_c('div',{staticClass:"product__header m-0 lg:hidden"},[_c('SfHeading',{staticClass:"sf-heading--no-underline sf-heading--left my-4 lg:hidden lg:my-0",attrs:{"title":_vm.getProductName(_vm.product),"level":3}})],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"flex flex-col lg:flex-row lg:gap-8"},[_c('div',{staticClass:"relative bg-white rounded-2xl lg:overflow-hidden lg:w-1/2 lg:rounded-none"},[_c('BoldDiscountLabel',{attrs:{"percentage":_vm.discountPercentage()}}),_vm._v(" "),(_vm.isAuthenticated)?_c('AddToWishlist',{staticClass:"absolute top-2.5 right-4 font-extrabold z-10",attrs:{"is-in-wishlist":_vm.isInWishlist},on:{"addToWishlist":function($event){return _vm.addItemToWishlist({product: _vm.product})}}}):_vm._e(),_vm._v(" "),_c('BoldPopup',{attrs:{"isOpen":_vm.galleryOpen},on:{"close":function($event){return _vm.toggleGalleryPopup()}}},[_c('SfLoader',{attrs:{"loading":_vm.isFetching}},[_c('BoldGallery',{staticClass:"hero",attrs:{"slider-options":{ autoplay: false },"pages":_vm.productGallery.length,"set-page":_vm.modalGalleryCurrent,"active":_vm.galleryOpen,"disable-keyboard-when-inactive":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var current = ref.current;
return _vm._l((_vm.productGallery),function(image,index){return _c('BoldGalleryItem',{key:index,attrs:{"focused":_vm.galleryOpen && current === index,"image":{
                    src : image.large.url,
                    alt : image.alt
                  },"media-type":image.media_type,"video-content-data":image.video_content_data}})})}}])})],1)],1),_vm._v(" "),_c('SfLoader',{attrs:{"loading":_vm.isFetching},on:{"click":function($event){return _vm.toggleGalleryPopup()}}},[_c('BoldGallery',{staticClass:"hero lg:border-2 lg:border-body-300 lg:rounded-3xl",attrs:{"slider-options":{ autoplay: false },"pages":_vm.productGallery.length,"active":!_vm.galleryOpen,"disable-keyboard-when-inactive":true},on:{"changePage":function($event){_vm.modalGalleryCurrent = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var current = ref.current;
return _vm._l((_vm.productGallery),function(image,index){return _c('BoldGalleryItem',{key:index,attrs:{"focused":!_vm.galleryOpen && current === index,"image":{
                  src : image.medium.url,
                  alt : image.alt
                },"media-type":image.media_type,"video-content-data":image.video_content_data,"pdp-image":true}})})}}])})],1),_vm._v(" "),_c('SfPrice',{attrs:{"regular":_vm.$fc(_vm.productPrice),"special":_vm.productSpecialPrice && _vm.$fc(_vm.productSpecialPrice)}}),_vm._v(" "),_c('BoldPrice',{staticClass:"sf-product-card__price lg:hidden border-0 lg:items-start",attrs:{"regular":_vm.$fc(_vm.productPrice),"special":_vm.$fc(_vm.productSpecialPrice),"percentage":_vm.discountPercentage()}})],1),_vm._v(" "),_c('div',{staticClass:"product__info mt-0 lg:mx-0 lg:px-0 lg:max-w-none lg:w-1/2 "},[(_vm.product !== null )?_c('div',{staticClass:"product-details flex flex-col gap-4"},[_c('h1',{staticClass:"hidden lg:flex mb-3 text-4xl text-system-400 font-bold leading-tight"},[_vm._v("\n            "+_vm._s(_vm.getProductName(_vm.product))+"\n          ")]),_vm._v(" "),(_vm.productShortDescription)?_c('BoldProductShortDescriptionSection',{staticClass:"bg-white product__description mt-4 p-4 rounded-2xl lg:rounded-none lg:p-0 lg:mt-0",attrs:{"mpbrandData":_vm.product.mpbrand,"brandLoading":_vm.brandLoading,"content":_vm.productShortDescription,"sku":_vm.product.sku}}):_vm._e(),_vm._v(" "),_c('BoldPrice',{staticClass:"mt-0 mx-4 bg-white hidden lg:flex lg:mx-0",attrs:{"regular":_vm.$fc(_vm.productPrice),"special":_vm.$fc(_vm.productSpecialPrice)}}),_vm._v(" "),_vm._l((_vm.configurableOptions),function(option){return [(option.attribute_code === 'color')?_c('div',{key:option.uid,staticClass:"product__colors my-0 lg:mx-3"},[_c('p',{staticClass:"product__color-label"},[_vm._v("\n                "+_vm._s(option.label)+":\n              ")]),_vm._v(" "),_vm._l((option.values),function(color){return _c('SfColor',{key:color.uid,staticClass:"product__color",attrs:{"color":_vm.getProductSwatchData(color.swatch_data),"color-uid":color.uid,"selected":_vm.productConfiguration[option.attribute_uid] === color.uid},on:{"click":function () { return _vm.updateProductConfiguration(
                  option.attribute_uid,
                  color.uid
                ); }}})})],2):_c('SfSelect',{key:option.uid,staticClass:"sf-select--underlined product__select-size",attrs:{"value":_vm.productConfiguration[option.attribute_uid],"label":option.label,"required":true},on:{"input":function ($event) { return _vm.updateProductConfiguration(option.attribute_uid, $event); }}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((option.values),function(attribute){return _c('SfSelectOption',{key:attribute.uid,attrs:{"value":attribute.uid}},[_vm._v("\n                "+_vm._s(attribute.label)+"\n              ")])})],2)]}),_vm._v(" "),_c('SfAddToCart',{directives:[{name:"e2e",rawName:"v-e2e",value:('product_add-to-cart'),expression:"'product_add-to-cart'"}],staticClass:"product__add-to-cart m-0 my-4",attrs:{"disabled":_vm.isCartLoading || !_vm.canAddToCart(_vm.product, _vm.qty) || _vm.isFetching},on:{"click":function($event){_vm.addItem({ product: _vm.product, quantity: parseInt(_vm.qty) })}},scopedSlots:_vm._u([{key:"add-to-cart-btn",fn:function(){return [_c('BoldButtonAddToCart',{staticClass:"w-full",attrs:{"product":_vm.product,"quantity":parseInt(_vm.qty),"productConfiguration":_vm.productConfiguration,"disabled":_vm.isCartLoading || !_vm.canAddToCart(_vm.product, _vm.qty) || _vm.isFetching,"neverRedirect":true}})]},proxy:true}],null,false,228835114),model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}}),_vm._v(" "),_c('SfAlert',{staticClass:"product__add-to-cart-error",style:({ display: !!_vm.addToCartError ? 'flex' : 'none'}),attrs:{"message":_vm.$t(_vm.addToCartError),"type":"danger"}}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('BoldShareBar',{attrs:{"share-url":_vm.shareUrl,"title":_vm.getProductName(_vm.product).replace(/<[^>]*>?/gm, ''),"description":_vm.productShortDescription.replace(/<[^>]*>?/gm, ''),"share-text":"Condividi prodotto"}})],1)],2):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }